import { Photo, Camera, CameraResultType } from "@capacitor/camera"
import React from "react"
import { toast } from "sonner"

type IUseImagePicker =
    | {
          multiple?: false
      }
    | {
          multiple: true
          totalFilesSelected: number
          maxFiles: number
      }

export function useImagePicker(params: IUseImagePicker) {
    const [photos, setPhotos] = React.useState<Photo[]>([])

    const capture = async () => {
        const photo = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            webUseInput: true,
        })

        if (photo) {
            if (params.multiple) {
                const { totalFilesSelected, maxFiles } = params
                if (totalFilesSelected + 1 > maxFiles) {
                    toast.error(`Sie können maximal ${maxFiles} Dateien auswählen`)
                    return
                }

                setPhotos([...photos, photo])
            } else {
                setPhotos([photo])
            }
        }
    }

    const reset = () => {
        setPhotos([])
    }

    const remove = (path: string) => {
        setPhotos(photos.filter((photo) => photo.webPath !== path))
    }

    return {
        capture,
        reset,
        remove,
        photos,
    }
}
