import { apiUrl } from "@api/apiConfig"
// import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { Button } from "@components/atoms/buttons"
import {
    CertificateSseReceiver,
    CertificateStatus,
    ProductId,
    EnergyCertificateVariant,
    EnergyCertificateType,
} from "@energuide/shared"
import { useEffect, useState } from "react"
import { toast } from "sonner"

interface Props {
    projectId: number
    onNext: () => void
    variant: EnergyCertificateVariant
    lastSentChatId: number
    type: EnergyCertificateType
    productId: ProductId
}

export function CertificateBoxProcessing(props: Props) {
    const { projectId, onNext, variant, productId } = props

    //TODO: create the final certificate
    // const _createCertificate = useCreateCertificate(projectId, type, lastSentChatId)
    const [receiver, setReceiver] = useState<CertificateSseReceiver<CertificateStatus> | undefined>()

    useEffect(() => {
        if (receiver) {
            receiver.close()
        }

        const newReceiver = new CertificateSseReceiver<CertificateStatus>(
            projectId.toString(),
            productId,
            variant,
            apiUrl
        )
        newReceiver.listen((msg) => {
            switch (msg.data) {
                case "processing": {
                    break
                }
                case "success": {
                    successToast()
                    onNext()
                    break
                }
                case "error": {
                    toast.error("Error processing payment")
                    break
                }
                default: {
                    console.error("unknown status")
                    break
                }
            }
        })

        setReceiver(newReceiver)

        return () => {
            newReceiver.close()
        }
    }, [projectId, productId, variant, onNext])

    const successToast = () => toast.success("Zertifikat ist fertig")

    return (
        <div>
            <p className="mb-4 mt-2 text-textLight">
                Der Ausweis wird von unseren Spezialisten überprüft und signiert. Dies kann einige Stunden dauern. Du
                erhältst eine E-Mail oder eine Push-Nachricht, sobald das Dokument zur Abholung bereit steht.
            </p>
            <div>
                <div className="flex justify-between">
                    <Button
                        loading={false}
                        disabled={true}
                        variant="secondary"
                        className="flex-1 cursor-not-allowed px-8"
                    >
                        Verarbeitung läuft...
                    </Button>
                </div>
            </div>
        </div>
    )
}
