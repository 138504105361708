import { usePaymentChannel } from "@hooks/usePaymentChannel"
import { useQuery } from "@hooks/useQuery"
import { useCallback, useEffect, useRef, useState } from "react"
import { toast } from "sonner"

const MAX_SECONDS = 3

const useStripeStatusHandler = (status: "success" | "error", onComplete: () => void) => {
    const query = useQuery()
    const sessionId = query.get("sessionId")

    const [seconds, setSeconds] = useState(MAX_SECONDS)
    const secondsRef = useRef(0)
    const { set } = usePaymentChannel()
    const intervalRef = useRef<null | NodeJS.Timeout>(null)

    const secondsCounter = useCallback(() => {
        secondsRef.current += 1
        setSeconds(MAX_SECONDS - secondsRef.current)
        if (secondsRef.current >= MAX_SECONDS) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
            onComplete()
        }
    }, [onComplete, setSeconds])

    useEffect(() => {
        if (!sessionId) {
            toast.error("Keine stripe sessionId gefunden")
        } else {
            set({ id: sessionId, status })
        }

        intervalRef.current = setInterval(secondsCounter, 1000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [sessionId, secondsCounter, set, status])

    return { seconds }
}

export { useStripeStatusHandler }
