import { Button } from "@components/atoms/buttons"
import { useTitlebar } from "@hooks/useTitlebar"
import { useStripeStatusHandler } from "@libs/stripe-lib"

//example url: http://localhost:5173/payment/error?sessionid=cs_test_a1NT7cFKxNpBzAp9Tz2M8lBMxcTZnhjV3HOoyPsXySRGfMIjo1AvqwiYiy

const StripeError = () => {
    useTitlebar({
        title: "Fehler bei der Zahlungsabwicklung",
        mode: "hide",
        showContextMenu: false,
    })
    const { seconds } = useStripeStatusHandler("error", () => null)

    return (
        <section className="flex h-full w-full flex-col items-center justify-center gap-8 px-20 py-40 font-text">
            <h1 className="text-wrap text-center text-heading1">Fehler bei der Zahlungsabwicklung 😞</h1>
            {seconds > 0 && (
                <Button variant="primary" className="w-full" onClick={() => window.close()}>
                    Schließen in {seconds} Sekunden
                </Button>
            )}
            {seconds <= 0 && <div>Ups! du musst den Tab manuell schließen ⚙️...</div>}
        </section>
    )
}

export { StripeError }
