import { PickedFile } from "@capawesome/capacitor-file-picker"

export async function getBlob(blobUrl: string) {
    const response = await fetch(blobUrl)
    const blob = await response.blob()
    return blob
}

export function pickedFileToUrl(file: PickedFile) {
    const { mimeType, data } = file

    if (!data) {
        throw new Error("Datei konnte nicht gelesen werden")
    }

    const base64Path = `data:${mimeType};base64,${data}`
    return base64Path
}
