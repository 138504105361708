import { IChat } from "@api/chat/schema"
import React from "react"

export enum ChatMode {
    regular = "regular",
    offScript = "offScript",
}

export function useChatMode(chatMessages: IChat[]) {
    const mode: ChatMode = React.useMemo(() => {
        const assistantMessages = chatMessages.filter((message) => message.role === "assistant")
        if (assistantMessages.length === 0) {
            return ChatMode.regular
        }

        const last = assistantMessages.pop()
        return last?.intent_type === "answer_user_question" ? ChatMode.offScript : ChatMode.regular
    }, [chatMessages])

    return mode
}
