import { baseUrl } from "@api/apiConfig"
import { EnergyCertificateTypeSchema } from "@energuide/shared"
import { z } from "zod"

const MediaAttributes = z.object({
    name: z.string(),
    alternativeText: z.string().nullable(),
    caption: z.string().nullable(),
    width: z.number().nullable(),
    height: z.number().nullable(),
    formats: z.any().nullable(),
    hash: z.string(),
    ext: z.string(),
    mime: z.string(),
    size: z.number(),
    url: z.string(),
    previewUrl: z.string().nullable(),
    provider: z.string(),
    provider_metadata: z.any().nullable(),
    createdAt: z.string(), // ISO 8601 date string
    updatedAt: z.string(), // ISO 8601 date string
})

const Media = z.object({
    id: z.number(),
    attributes: MediaAttributes,
})

const EnergyCertificateComponent = z.object({
    id: z.number(),
    error: z.string().nullable(),
    src: z
        .object({
            data: z.array(Media).nullable(),
        })
        .nullish(),
})

const ProjectAttributes = z.object({
    name: z.string(),
    cover: z.object({
        data: z
            .object({
                attributes: MediaAttributes.nullable(),
            })
            .nullable(),
    }),
})

const CertificateAttributes = z.object({
    taskId: z.string(),
    createdAt: z.string(), // ISO 8601 date string
    updatedAt: z.string(), // ISO 8601 date string
    type: EnergyCertificateTypeSchema,
    druckappInput: z.string().nullable(),
    draft: EnergyCertificateComponent.nullable(),
    final: EnergyCertificateComponent.nullable(),
    lastSentChatId: z.number().nullable(),
    project: z
        .object({
            data: z.object({
                id: z.number(),
                attributes: ProjectAttributes,
            }),
        })
        .nullish(),
    comment: z.string().nullish(),
})

export const EnergyCertificateResponse = z.object({
    id: z.number(),
    attributes: CertificateAttributes,
})

export type IEnergyCertificateResponse = z.infer<typeof EnergyCertificateResponse>

export const EnergyCertificatesResponse = z.array(EnergyCertificateResponse)
export type IEnergyCertificatesResponse = z.infer<typeof EnergyCertificatesResponse>

export function transform(data: IEnergyCertificateResponse) {
    const fileDraft = data.attributes.draft
    const draftUrl = fileDraft?.src?.data?.at(0)?.attributes.url

    const fileFinal = data.attributes.final
    const finalUrl = fileFinal?.src?.data?.at(0)?.attributes.url

    const project = data.attributes.project
    const cover = project?.data.attributes.cover?.data

    return {
        id: data.id,
        taskId: data.attributes.taskId,
        project: project
            ? {
                  id: project?.data.id,
                  name: project?.data.attributes.name,
                  cover: cover ? `${baseUrl}${cover?.attributes?.url}` : null,
              }
            : null,

        draft: fileDraft
            ? {
                  ...fileDraft,
                  src: draftUrl ? `${baseUrl}${draftUrl}` : null,
              }
            : null,

        final: fileFinal
            ? {
                  ...fileFinal,
                  src: finalUrl ? `${baseUrl}${finalUrl}` : null,
              }
            : null,
        type: data.attributes.type,
        druckappInput: data.attributes.druckappInput,
        lastSentChatId: data.attributes.lastSentChatId,
        comment: data.attributes.comment,
        createdAt: data.attributes.createdAt,
    }
}

export type IEnergyCertificate = ReturnType<typeof transform>
