import { useState, useCallback } from "react"
import { IBuilding, CapturedRoom, LidarPluginError, LidarPluginErrorType, handleError } from "."
import { BuildingStorage } from "./storage/buildingStorage"

const storage = new BuildingStorage()
const useBuilding = () => {
    const [buildingMap, setBuildingMap] = useState(new Map<number, IBuilding>())

    const updateAndSaveBuilding = useCallback(
        async (key: number, updateFn: (building: IBuilding) => IBuilding): Promise<boolean> => {
            let error: LidarPluginError | undefined
            let newBuilding: IBuilding | undefined

            const updatedMap = new Map(buildingMap)
            const existingBuilding = updatedMap.get(key)

            if (existingBuilding) {
                newBuilding = updateFn(existingBuilding)
                updatedMap.set(key, newBuilding)
            } else {
                error = new LidarPluginError("Building does not exist", LidarPluginErrorType.IdentifierDoesNotExist)
            }

            if (error) {
                handleError(error)
                return false
            } else {
                setBuildingMap(updatedMap) // Apply the new map
                if (newBuilding) {
                    await storage.save(newBuilding)
                }
                return true
            }
        },
        [buildingMap]
    )

    const createBuilding = useCallback(async (buildingName: string, id: number): Promise<IBuilding> => {
        const newBuilding = { buildingName, id: id, world: null }
        setBuildingMap((prevMap) => {
            const updatedMap = new Map(prevMap)
            updatedMap.set(id, newBuilding)
            return updatedMap
        })
        await storage.save(newBuilding)
        return newBuilding
    }, [])

    const retrieveBuilding = useCallback((key: number): IBuilding | undefined => buildingMap.get(key), [buildingMap])

    const isRoom = useCallback(
        (buildingKey: number, roomKey: string) =>
            buildingMap.get(buildingKey)?.world?.rooms.find((room) => room.identifier === roomKey) !== undefined,
        [buildingMap]
    )

    const renameBuilding = useCallback(
        async (key: number, newName: string): Promise<boolean> => {
            return await updateAndSaveBuilding(key, (building) => ({ ...building, buildingName: newName }))
        },
        [updateAndSaveBuilding]
    )

    const setWorldId = useCallback(
        async (key: number, worldId: string): Promise<boolean> => {
            return await updateAndSaveBuilding(key, (building) => ({ ...building, world: { id: worldId, rooms: [] } }))
        },
        [updateAndSaveBuilding]
    )

    const addRoom = useCallback(
        async (key: number, newRoom: CapturedRoom) => {
            return await updateAndSaveBuilding(key, (building) => {
                if (building.world === null) {
                    handleError(
                        new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                    )
                    return building
                } else {
                    return {
                        ...building,
                        world: { id: building.world?.id, rooms: [...building.world!.rooms, newRoom] },
                    }
                }
            })
        },
        [updateAndSaveBuilding]
    )

    const updateRoom = useCallback(
        async (key: number, updatedRoom: CapturedRoom) => {
            return await updateAndSaveBuilding(key, (building) => {
                if (building.world === null) {
                    handleError(
                        new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                    )
                    return building
                } else {
                    return {
                        ...building,
                        world: {
                            id: building.world?.id,
                            rooms: building.world.rooms.map((room) =>
                                room.identifier === updatedRoom.identifier ? updatedRoom : room
                            ),
                        },
                    }
                }
            })
        },
        [updateAndSaveBuilding]
    )

    const removeRoom = useCallback(
        async (key: number, roomId: string) => {
            await updateAndSaveBuilding(key, (building) => {
                if (building.world === null) {
                    handleError(
                        new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                    )
                    return building
                } else {
                    return {
                        ...building,
                        world: {
                            id: building.world?.id,
                            rooms: building.world.rooms.filter((room) => room.identifier !== roomId),
                        },
                    }
                }
            })
        },
        [updateAndSaveBuilding]
    )

    const deleteBuilding = useCallback(async (key: number) => {
        setBuildingMap((prevMap) => {
            const updatedMap = new Map(prevMap)
            updatedMap.delete(key)
            return updatedMap
        })
        await storage.delete(String(key))
    }, [])

    const refreshFromStorage = useCallback(async (): Promise<IBuilding[] | null> => {
        const data = await storage.list()
        if (data) {
            setBuildingMap(new Map(data.map((building) => [building.id, building])))
        }
        return data
    }, [])

    return {
        buildingMap,
        createBuilding,
        retrieveBuilding,
        setWorldId,
        addRoom,
        removeRoom,
        deleteBuilding,
        refreshFromStorage,
        renameBuilding,
        updateRoom,
        isRoom,
    }
}
export { useBuilding }
