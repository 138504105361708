import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"

const LastSentChatIdSchema = z.object({
    last_sent_message_chat_id: z.number().optional(),
})

type IUseChats = {
    enabled?: boolean
}

export function useLastSentChatId(projectId: number, params?: IUseChats) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["chat", projectId],
        queryFn: async () => {
            const data = await handleFetch(() =>
                fetch(`${apiUrl}/chat/lastChatId?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            if (!data) {
                return null
            }

            const lastSentChatId = handleParse(() => LastSentChatIdSchema.parse(data))
            return lastSentChatId
        },
        refetchOnMount: true,
        enabled: enabled && !!authHeader && !!projectId,
    })
}
