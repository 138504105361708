import { ImageSchema } from "@api/image"
import { RoleTypes } from "@energuide/shared"
import { z } from "zod"

export const RoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    type: z.nativeEnum(RoleTypes),
})

export const UserSchema = z.object({
    id: z.number(),
    email: z.string(),
    pendingEmail: z.string().nullish(),
    displayName: z.string().nullish(),
    provider: z.string(),
    confirmed: z.boolean(),
    avatar: ImageSchema.nullish(),
    role: RoleSchema.nullish(),
})

export type IUser = z.infer<typeof UserSchema>

export const useUserKey = ["users", "me"]
