import { ProductIdentifier, ProductId } from "../../product"
import { EnergyCertificateVariant } from "../schema"

class CertificateSse {
    private _projectId: string
    private _productId: ProductId
    private _variant: EnergyCertificateVariant

    get identifier(): ProductIdentifier {
        return `${this._projectId}-${this._productId}-${this._variant}`
    }
    get projectId() {
        return this._projectId
    }
    get productId() {
        return this._productId
    }
    get variant() {
        return this._variant
    }

    constructor(projectId: string, productId: ProductId, variant: EnergyCertificateVariant) {
        this._projectId = projectId
        this._productId = productId
        this._variant = variant
    }
}

export { CertificateSse }
