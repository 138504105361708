import { useCallback, useMemo } from "react"

export type PaymentStatus = "processing" | "success" | "error"

export type PaymentMessage = {
    status: PaymentStatus
    id: string
}

export function usePaymentChannel() {
    const bc = useMemo(() => new BroadcastChannel("payment_channel"), [])

    const set = useCallback(
        (msg: PaymentMessage) => {
            bc.postMessage(msg)
        },
        [bc]
    )

    const listen = useCallback(
        (cb: (msg: PaymentMessage) => void) => {
            bc.onmessage = (event) => cb(event.data)
        },
        [bc]
    )

    const close = useCallback(() => {
        bc.close()
    }, [bc])

    return {
        set,
        listen,
        close,
    }
}
