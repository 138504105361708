import { apiUrl, handleFetch } from "@api/apiConfig"
import { ProductId } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"

export type IUseOrder = {
    productId: ProductId
    projectId: number
}

export type IOrderResult = {
    message: string
}

export function useDeleteOrder() {
    const authHeader = useAuthHeader()

    return useMutation<IOrderResult | null, Error, IUseOrder>({
        mutationFn: async (params: IUseOrder): Promise<IOrderResult | null> => {
            const { productId, projectId } = params

            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/order?productId=${productId}&projectId=${projectId}`, {
                    method: "DELETE",
                    headers: {
                        ...authHeader,
                    },
                })
                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            return response
        },
    })
}
