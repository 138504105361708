import { cls } from "@utils"
import React from "react"
import { TextVariant, variants } from "."

export type IText = React.PropsWithChildren<{
    className?: string
    variant: TextVariant
}>

export const Text = React.forwardRef<HTMLParagraphElement, IText>((props, ref) => {
    const { children, className, variant } = props
    return (
        <p className={cls(variants[variant], "overflow-hidden text-ellipsis", className)} ref={ref}>
            {children}
        </p>
    )
})
