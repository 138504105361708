import { IEnergyCertificate } from "@api/certificate/schema"
import { GradientBackground } from "@components/atoms/gradient"
import { EnergyCertificatTypeMap } from "@energuide/shared"
import { format } from "date-fns"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"

function ReviewCardAttribute(props: PropsWithChildren) {
    const { children } = props
    return <span className="text-textLight">{children}</span>
}

function ReviewCardContent(props: PropsWithChildren) {
    const { children } = props
    return <span className="overflow-hidden text-ellipsis whitespace-nowrap text-text">{children}</span>
}

type ReviewCardProps = {
    certificate?: IEnergyCertificate | null
}

export function ReviewCard(props: ReviewCardProps) {
    const { certificate } = props
    const { id, type, taskId, createdAt, project } = certificate ?? {}

    return (
        <GradientBackground
            variant="light"
            radius="small"
            onClick={() => {}}
            className="bg-primary/15 hover:bg-primary/25"
        >
            <Link to={`/authenticated/review/${id}`}>
                {certificate?.project?.cover && (
                    <img src={certificate?.project?.cover ?? ""} alt="house image" className="w-full" />
                )}
                <div className="grid grid-cols-[auto,minmax(0,1fr)] gap-x-4 gap-y-2 p-4">
                    <ReviewCardAttribute>Typ</ReviewCardAttribute>
                    <ReviewCardContent>{type ? EnergyCertificatTypeMap[type] : ""}</ReviewCardContent>

                    <ReviewCardAttribute>Projekt</ReviewCardAttribute>
                    <ReviewCardContent>{project?.name ?? ""}</ReviewCardContent>

                    <ReviewCardAttribute>Projekt ID</ReviewCardAttribute>
                    <ReviewCardContent>{project?.id ?? ""}</ReviewCardContent>

                    <ReviewCardAttribute>Task ID</ReviewCardAttribute>
                    <ReviewCardContent>{taskId ?? ""}</ReviewCardContent>

                    <ReviewCardAttribute>Erstellt</ReviewCardAttribute>
                    <ReviewCardContent>{createdAt ? format(createdAt, "dd.MM.yyyy - kk:mm") : ""}</ReviewCardContent>
                </div>
            </Link>
        </GradientBackground>
    )
}
