import { Capacitor, PluginListenerHandle } from "@capacitor/core"
import { Keyboard } from "@meerpohl/keyboard"
import React from "react"

export function useScroll<T extends HTMLElement>(
    scrollTo: "top" | "bottom",
    scrollOnAdd: boolean,
    scrollBehavior?: ScrollBehavior,
    filter?: (addedNodes: NodeList) => boolean
) {
    const ref = React.useRef<T | null>(null)
    const keyboardAvailable = Capacitor.isPluginAvailable("Keyboard")

    const scroll = React.useCallback((scrollTo: "top" | "bottom", scrollBehavior?: ScrollBehavior) => {
        ref.current?.scrollTo({
            top: scrollTo === "top" ? 0 : ref.current.scrollHeight,
            behavior: scrollBehavior ?? "instant",
        })
    }, [])

    React.useEffect(() => {
        const scroller = ref.current

        if (!scroller) {
            return
        }

        const observer = new MutationObserver((mutations) => {
            if (!filter) {
                scroll(scrollTo, scrollBehavior)
            }

            for (const mutation of mutations) {
                if (filter?.(mutation.addedNodes)) {
                    scroll(scrollTo, scrollBehavior)
                }
            }
        })

        let scrollHandler: PluginListenerHandle | null = null
        if (scrollOnAdd) {
            if (keyboardAvailable) {
                scrollHandler = Keyboard.addListener("keyboardDidShow", () => {
                    if (!(document.activeElement instanceof HTMLTextAreaElement)) {
                        return
                    }

                    setTimeout(() => {
                        scroll(scrollTo, scrollBehavior ?? "instant")
                    }, 100)
                })
            }

            observer.observe(scroller, {
                childList: true,
                subtree: true,
            })
        }

        return () => {
            if (keyboardAvailable && scrollHandler) {
                void scrollHandler.remove()
            }
            observer.disconnect()
        }
    }, [keyboardAvailable, scrollBehavior, scrollOnAdd, scrollTo, filter, scroll])

    return {
        ref,
        manualScroll: scroll,
    }
}
