import { z } from "zod"
import { EnergyCertificateCollectionSchema } from "../energy-certificate"
import { ProductSchema, ProductIdSchema } from "../product"

const OrderSchema = z.object({
    id: z.number(),
    email: z.string().email(),
    stripe_session_id: z.string(),
    stripe_payment_intent: z.string().nullable(),
    product: ProductSchema,
    energy_certificate: EnergyCertificateCollectionSchema.nullable(),
    is_paid: z.boolean(),
    is_captured: z.boolean(),
    is_cancelled: z.boolean(),
    error: z.string().nullable(),
})
type IOrder = z.infer<typeof OrderSchema>

const orderPostSchema = z.object({
    email: z.string().email(),
    productId: ProductIdSchema,
    projectId: z.number().positive(),
    certificateId: z.number(),
})

type OrderPost = z.infer<typeof orderPostSchema>

export { OrderSchema, orderPostSchema }
export type { IOrder, OrderPost }
