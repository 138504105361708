import { useChats } from "@api/chat/useChats.ts"
import { useCreateChat } from "@api/chat/useCreateChat.ts"
import { useInitChat } from "@api/chat/useInitChat.ts"
import { useFaqHints } from "@api/faq-hints/useFaqHints.ts"
import { useProject } from "@api/projects/useProject.ts"
import { EnerGuide } from "@components/atoms/energuide/energuide-bright.tsx"
import { Heading } from "@components/atoms/typography.tsx"
import { ChatModeButton } from "@components/molecules/chat-mode-button"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { Scrollable } from "@components/molecules/scrollable.tsx"
import { SearchInput } from "@components/molecules/search-input"
import { ChatHints } from "@components/organisms/chat-hints.tsx"
import { ChatInput } from "@components/organisms/chat-input.tsx"
import { ChatMessages } from "@components/organisms/chat-messages.tsx"
import { ChatMode, useChatMode } from "@hooks/useChatMode"
import { useFileSelection } from "@hooks/useFileSelection"
import { useIntentType } from "@hooks/useIntentType.ts"
import { useScroll } from "@hooks/useScroll.ts"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import * as Form from "@radix-ui/react-form"
import { cls } from "@utils"
import React from "react"
import { useParams } from "react-router"

function chatMessageFilter(nodes: NodeList): boolean {
    for (const node of nodes) {
        if (node.nodeType !== Node.ELEMENT_NODE) {
            continue
        }
        const element = node as HTMLElement
        const isChatMessage = element.classList.contains("chat-message")
        const isChatMessageContent = !!element.closest(".chat-message")
        const isImage = element instanceof HTMLImageElement

        if (isChatMessage || (isChatMessageContent && !isImage)) {
            return true
        }
    }

    return false
}

export function Chat() {
    const appbarSize = useAppState((state) => state.appbarSize)

    const { id } = useParams()
    const { data: project } = useProject(Number(id))
    const { ref: contentRef } = useScroll<HTMLDivElement>("bottom", true, "smooth", chatMessageFilter)
    const { data: chats } = useChats(Number(id))
    const chatMode = useChatMode(chats ?? [])
    const { data: hints } = useFaqHints({ enabled: chats && chats.length === 0 })
    const createChatMutation = useCreateChat(Number(id))
    useInitChat(chats, createChatMutation)

    const { photos, capture, resetImages, removeImage, files, pickFiles, resetFiles, removeFile } = useFileSelection({
        maxFiles: 5,
    })

    const { ref: intentRef, onChoiceIntent } = useIntentType(chats ?? [])

    const formRef = React.useRef<HTMLFormElement | null>(null)
    const chatInputRef = React.useRef<HTMLTextAreaElement | null>(null)

    useTitlebar({
        title: project?.name ?? "",
        mode: "menu",
        showContextMenu: true,
        showSearchIcon: true,
        contextMenuProps: {
            showBuildingPass: true,
            showEnergyCertificates: true,
            showProjectSettings: true,
            showRoomManagement: true,
        },
        projectId: id ? Number(id) : null,
    })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        const ref = chatInputRef.current
        const message = ref?.value ?? ""
        const intent_type = intentRef.current?.value ?? ""

        const hasContent = message || photos.length || files.length
        if (!hasContent) {
            return
        }

        createChatMutation.mutate({ message, photos, files, intent_type })

        ref?.focus()
        formRef.current?.reset()
        resetImages()
        resetFiles()
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault()
            formRef.current?.requestSubmit()
        }
    }

    const onLoadMessage = (message: string) => {
        if (!chatInputRef.current) {
            return
        }

        chatInputRef.current.value = message
    }

    const onChoiceSelected = (message: string) => {
        onLoadMessage(message)
        onChoiceIntent()
        formRef.current?.requestSubmit()
    }

    const onSearch = (term: string): HTMLElement[] => {
        if (term === "") {
            return []
        }

        const filteredChats =
            chats?.filter((chat) => {
                const lowerContent = chat.content?.toLocaleLowerCase() ?? ""
                const lowerTerm = term.toLocaleLowerCase()
                return lowerContent?.includes(lowerTerm)
            }) ?? []

        const elements = filteredChats
            .map((chat) => {
                return document.getElementById(`message-${chat.id}`)!
            })
            .filter((element) => Boolean(element))

        return elements
    }

    const continueChat = () => {
        createChatMutation.mutate({
            intent_type: "init_data_collection",
            message: "",
            photos: [],
            files: [],
        })
    }

    return (
        <ContentOffset
            safeAreas={true}
            offsetAppbar={false}
            className="grid h-dvh grid-rows-[minmax(0,1fr)_auto] overflow-hidden"
        >
            <SearchInput onSearch={onSearch} />

            <Scrollable ref={contentRef}>
                <div
                    className={cls(
                        "mx-auto grid h-full max-w-screen-lg content-end gap-6 p-4 sm:gap-12 sm:p-6 relative",
                        { "pb-12 sm:pb-12": chatMode === ChatMode.offScript }
                    )}
                    style={{ paddingTop: `${appbarSize[1]}px` }}
                >
                    <div className="grid">
                        <div className="h-32">
                            <EnerGuide
                                glow
                                className="grid h-52 translate-y-[-80%] lg:translate-y-[-70%]"
                                energuideClassName="h-72 w-72 justify-self-center"
                            />
                        </div>
                        <Heading level="h4" className="whitespace-pre-line pl-16 text-text">
                            Hallo!
                            <br />
                            Ich bin EnerGuide, <br />
                            dein digitaler Assistent für alles rund um den energetischen Zustand deines Gebäudes.
                        </Heading>
                    </div>

                    {chats && chats.length ? (
                        <ChatMessages chats={chats} onChoiceSelected={onChoiceSelected} />
                    ) : (
                        <ChatHints hints={hints ?? []} onClick={onLoadMessage} />
                    )}
                </div>
            </Scrollable>

            <div className="gradient-border-dark mx-auto w-full max-w-screen-lg border-t p-2 lg:border-0 relative">
                <ChatModeButton mode={chatMode} onClick={continueChat} className="absolute top-[-36px] w-full z-10" />
                <Form.Root onSubmit={onSubmit} ref={formRef}>
                    <input ref={intentRef} readOnly hidden name="intent_type" />
                    <ChatInput
                        ref={chatInputRef}
                        photos={photos}
                        onCaptureImage={capture}
                        onRemoveImage={removeImage}
                        files={files}
                        onPickFile={pickFiles}
                        onRemoveFile={removeFile}
                        onKeyDown={onKeyDown}
                    />
                </Form.Root>
            </div>
        </ContentOffset>
    )
}
