import { IChat } from "@api/chat/schema"
import React from "react"

type AssistantIntentType = "ask_choice" | "ask_add_task" | "answer_user_question"

type UserIntentType = "answer_choice" | "answer_add_task" | "init_data_collection"

type IntentType = AssistantIntentType | UserIntentType

function findLastAssistantIntent(chats: IChat[]): IntentType | null | undefined {
    const copy = [...chats]
    let chat = copy.pop()
    while (chat && chat.role !== "assistant") {
        chat = copy.pop()
    }

    const { intent_type } = chat ?? {}
    return intent_type as IntentType | null | undefined
}

export function useIntentType(chats: IChat[]) {
    const ref = React.useRef<HTMLInputElement>(null)

    const setIntentType = (intentType: IntentType | null) => {
        if (!ref.current) {
            return
        }

        ref.current.value = intentType ?? ""
    }

    const onChoiceIntent = () => {
        const intentType = findLastAssistantIntent(chats)
        if (intentType === "ask_add_task") {
            setIntentType("answer_add_task")
        } else if (intentType === "ask_choice") {
            setIntentType("answer_choice")
        } else {
            setIntentType(null)
        }
    }

    return {
        ref,
        onChoiceIntent,
    }
}
