import { Button } from "@components/atoms/buttons"
import { useRef } from "react"

interface Props {
    certificateUrl: string
}

export function CertificateBoxDownload(props: Props) {
    const { certificateUrl } = props
    const downloadLinkRef = useRef<HTMLAnchorElement>(null) // Reference to the anchor element

    function downloadCertificate() {
        if (certificateUrl && downloadLinkRef.current) {
            downloadLinkRef.current.href = certificateUrl
            downloadLinkRef.current.click()
        } else {
            console.warn("Certificate URL not available")
        }
    }

    return (
        <div>
            {/* Hidden anchor element used for downloading */}
            <a ref={downloadLinkRef} target="_blank" style={{ display: "none" }} download />

            <p className="mb-4 mt-2 text-textLight">
                <span className="font-bold">Erfolg!</span> Dein Ausweis steht bereit. Klicke auf den Button, um ihn
                herunterzuladen.
            </p>
            <div>
                <div className="flex justify-between">
                    <Button
                        loading={false}
                        disabled={!certificateUrl}
                        variant="primary"
                        onClick={downloadCertificate}
                        className="flex-1 px-8"
                    >
                        Herunterladen
                        <i className="ri-file-download-line text-[1.25rem]"></i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
