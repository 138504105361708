import { useRemoveUser } from "@api/user/useRemoveUser"
import { Button } from "@components/atoms/buttons"
import { icons } from "@components/organisms/changeable-field"
import { DataModal } from "@components/organisms/data-modal"
import * as Form from "@radix-ui/react-form"
import React from "react"

export function DeleteAccount() {
    const [open, setOpen] = React.useState(false)
    const removeUserMutation = useRemoveUser()

    const onDelete: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        await removeUserMutation.mutateAsync()
    }

    return (
        <DataModal
            trigger={
                <Button variant="warning" type="button" data-testid="account-delete-button">
                    Account unwiderruflich löschen
                </Button>
            }
            open={open}
            onOpenChange={setOpen}
            iconClass={icons["delete"]}
            title="Account löschen"
            description="Bist du sicher, dass du deinen Account wirklich unwiderruflich löschen willst?"
        >
            <Form.Root onSubmit={onDelete} className="grid gap-4">
                <Form.Submit asChild className="border-black border px-8 py-2">
                    <Button variant="warning" data-testid="account-delete-confirm-button">
                        Account löschen
                    </Button>
                </Form.Submit>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => setOpen(false)}
                    data-testid="account-delete-abort-button"
                >
                    Nein doch nicht
                </Button>
            </Form.Root>
        </DataModal>
    )
}
