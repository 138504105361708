/**
 * Currency module that defines a currency type based on ISO 4217 standards.
 *
 * This module provides an interface and a class to represent currencies.
 * The Currency class ensures that values are provided in the smallest unit
 * of the currency (e.g., cents for USD, Euro cents for EUR).
 *
 * Example usage:
 *
 * ```typescript
 * import { Currency } from "./currency";
 *
 * const euroCurrency = new Currency('EUR', 'Euro', '€', 200);
 * console.log(euroCurrency.formatted); // Output: 2,00 €
 * ```
 *
 * @module Currency
 */
import { ISO4217CurrencyCode } from "./codes"

interface ICurrency {
    code: ISO4217CurrencyCode // ISO 4217 currency code, e.g., 'EUR' for Euro
    name: string // Full name of the currency, e.g., 'Euro'
    symbol: string // Currency symbol, e.g., '€'
    value: number // Value in cents, as an integer (100 cents = 1 euro)
}

class Currency implements ICurrency {
    code: ISO4217CurrencyCode
    name: string
    symbol: string
    value: number

    /**
     * Creates an instance of the Currency class.
     *
     * @param {ISO4217CurrencyCode} code - The ISO 4217 currency code, e.g., 'EUR' for Euro.
     * @param {string} name - The full name of the currency, e.g., 'Euro'.
     * @param {string} symbol - The currency symbol, e.g., '€'.
     * @param {number} value - The value of the currency in its smallest unit (e.g., cents for Euro). This must be an integer.
     *
     * @throws {Error} If the value is not an integer.
     */
    constructor(code: ISO4217CurrencyCode, name: string, symbol: string, value: number) {
        if (!Number.isInteger(value)) {
            throw new Error("Value must be an integer (whole number).")
        }
        this.code = code
        this.name = name
        this.symbol = symbol
        this.value = value
    }

    /**
     * Returns the value of the currency in whole euros.
     *
     * @returns {number} The value in euros.
     */
    get valueInEuros(): number {
        return Math.floor(this.value / 100)
    }

    /**
     * Returns the remaining cents of the currency value.
     *
     * @returns {number} The remaining cents.
     */
    get remainingCents(): number {
        return this.value % 100
    }

    /**
     * Returns the formatted string representation of the currency.
     *
     * @returns {string} The formatted currency string.
     */
    get formatted(): string {
        return `${this.valueInEuros},${this.remainingCents.toString().padStart(2, "0")} ${this.symbol}`
    }
}

export type { ICurrency, ISO4217CurrencyCode }
export { Currency }
