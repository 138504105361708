import { apiUrl, handleFetch } from "@api/apiConfig"
import { ProductId, ProductSchema } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export function transform(data: any) {
    return data
}

export function useProduct(id: ProductId) {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["product", id],
        queryFn: async () => {
            const product = await handleFetch(() =>
                fetch(`${apiUrl}/product?id=${id}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )
            if (!product) {
                return null
            }

            return ProductSchema.parse(transform(product))
        },
    })
}
