import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { EnergyCertificateType, EnergyCertificateVariant, IEnergyCertificateCollection } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useCreateCertificate(
    projectId: number,
    type: EnergyCertificateType,
    lastSentChatId: number,
    variant: EnergyCertificateVariant,
    comment?: string
) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/energy-certificates`, {
                    method: "post",
                    body: JSON.stringify({
                        data: {
                            projectId,
                            type,
                            lastSentChatId,
                            variant,
                            comment,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async (data: IEnergyCertificateCollection) => {
            await queryClient.invalidateQueries({
                queryKey: ["certificate", projectId, type],
            })
            await queryClient.invalidateQueries({
                queryKey: ["draft", data.id],
            })

            toast.success("Ausweis wird erstellt!")
        },
    })
}
