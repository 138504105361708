import { apiUrl, baseUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useUserKey, UserSchema } from "@api/user/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseUser = {
    enabled?: boolean
}

export function useUser(params?: IUseUser) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: useUserKey,
        queryFn: async () => {
            const data = await handleFetch(() =>
                fetch(`${apiUrl}/users/me?populate[0]=avatar&populate[1]=role`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const user = handleParse(() =>
                UserSchema.parse({
                    ...data,
                    avatar: {
                        ...data.avatar,
                        url: data.avatar?.url ? `${baseUrl}${data.avatar?.url}` : null,
                    },
                })
            )

            return user
        },
        enabled: enabled && !!authHeader,
    })
}
