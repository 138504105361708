import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type ICreate = {
    name: string
}

export function useCreateProject() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: ICreate) => {
            if (!authHeader) {
                return null
            }

            const { name } = params
            return handleFetch(() =>
                fetch(`${apiUrl}/projects`, {
                    method: "post",
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        data: {
                            name,
                        },
                    }),
                })
            )
        },
        onSuccess: async () => {
            toast.success("Projekt erstellt")
            await queryClient.invalidateQueries({
                queryKey: ["projects"],
            })
        },
    })
}
