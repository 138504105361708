import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { OrderPost, OrderSchema, ProductId } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import React from "react"

const stripeInstances: Record<string, Stripe | null> = {}

export const useStripe = (pk: string) => {
    const stripePromise: Promise<Stripe | null> = loadStripe(pk)

    const initializeStripe = async () => {
        if (!stripeInstances[pk]) {
            stripeInstances[pk] = await loadStripe(pk)
        }
        return stripeInstances[pk]
    }

    const authHeader = useAuthHeader()
    const order = async (params: OrderPost) => {
        return await handleFetch(() =>
            fetch(`${apiUrl}/order`, {
                method: "POST",
                headers: {
                    ...authHeader,
                },
                body: JSON.stringify(params),
            })
        )
    }

    const createCheckoutSession = async (sessionId: string) => {
        const stripe = await initializeStripe()
        if (!stripe) {
            throw new Error("Stripe failed to initialize.")
        }

        return stripe.redirectToCheckout({
            sessionId,
        })
    }

    const refreshOrderStatus = async (projectId: number, productId: ProductId) => {
        const response = await handleFetch(() =>
            fetch(`${apiUrl}/order/refresh?projectId=${projectId}&productId=${productId}`, {
                method: "POST",
                headers: {
                    ...authHeader,
                },
            })
        )
        if (!response) {
            return null
        }
        return handleParse(() => OrderSchema.parse(response))
    }

    //! Not being used yet
    const StripeElements = (props: React.PropsWithChildren<{ options?: any }>) => {
        const { children, options } = props

        if (!options) {
            return <div>Loading...</div>
        }

        return (
            <Elements stripe={stripePromise} options={options}>
                {children}
            </Elements>
        )
    }

    return {
        createCheckoutSession,
        StripeElements,
        order,
        refreshOrderStatus,
    }
}
