import { FilePicker, PickedFile } from "@capawesome/capacitor-file-picker"
import React from "react"
import { toast } from "sonner"

type IUseFilePicker = {
    totalFilesSelected: number
    maxFiles: number
}

export function useFilePicker(params: IUseFilePicker) {
    const { totalFilesSelected, maxFiles } = params
    const [files, setFiles] = React.useState<PickedFile[]>([])

    const pickFiles = async () => {
        const result = await FilePicker.pickFiles({
            types: ["image/png", "image/jpeg", "application/pdf"],
            readData: true,
        })

        if (totalFilesSelected + result.files.length > maxFiles) {
            toast.error(`Sie können maximal ${maxFiles} Dateien auswählen`)
            return
        }

        setFiles([...files, ...result.files])
    }

    const reset = () => {
        setFiles([])
    }

    const remove = (file: PickedFile) => {
        setFiles(files.filter((f) => f !== file))
    }

    return {
        pickFiles,
        reset,
        remove,
        files,
    }
}
