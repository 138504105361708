import { Capacitor, PluginListenerHandle } from "@capacitor/core"
import { Keyboard } from "@meerpohl/keyboard"
import React from "react"

const keyboardAvailable = Capacitor.isPluginAvailable("Keyboard")

export function useInputFocus<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>() {
    const ref = React.useRef<T | null>(null)

    // automatically scroll the currently focused input to the center of the screen
    React.useEffect(() => {
        let handler: PluginListenerHandle | null
        if (keyboardAvailable) {
            handler = Keyboard.addListener("keyboardDidShow", () => {
                if (document.activeElement !== ref.current) {
                    return
                }

                ref.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                })
            })
        }

        return () => {
            void handler?.remove()
        }
    }, [])

    return ref
}
