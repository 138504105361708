import { Capacitor } from "@capacitor/core"
import { StatusBar } from "@capacitor/status-bar"
import { ErrorFallback } from "@components/atoms/error-fallback"
import { StyledToaster } from "@components/atoms/toaster"
import { InitialProjectLoader } from "@components/molecules/initial-project-loader"
import { AppleRedirect, FacebookRedirect, GoogleRedirect, UserRedirect } from "@components/organisms/oauth-redirect"
import { useDeepLinkRedirect } from "@hooks/useDeepLinkRedirect"
import { useIntro } from "@hooks/useIntro"
import { useAppState } from "@hooks/useState"
import { AuthLayout } from "@layouts/auth"
import { AuthenticatedLayout } from "@layouts/authenticated"
import { Account } from "@pages/account"
import { Confirmation } from "@pages/auth/confirmation"
import { ForgotPassword } from "@pages/auth/forgot-password"
import { ForgotConfirmation } from "@pages/auth/forgotConfirmation"
import { InvalidToken } from "@pages/auth/invalid-token"
import { Login } from "@pages/auth/login"
import { LoginSelection } from "@pages/auth/login-selection"
import { Register } from "@pages/auth/register"
import { RegisterAdditions } from "@pages/auth/register-additions"
import { RegisterSelection } from "@pages/auth/register-selection"
import { ResetPassword } from "@pages/auth/reset-password"
import { BuildingPass } from "@pages/building-pass"
import { Chat } from "@pages/chat"
import { DataOverview } from "@pages/data"
import { DataProtection } from "@pages/data-protection"
import { EnergyCertificates } from "@pages/energy-certificates"
import { Intro } from "@pages/intro"
import { ProjectOverview } from "@pages/project-overview"
import { ProjectSettings } from "@pages/project-settings"
import { ReviewConfirmation } from "@pages/reviews/review-confirmation"
import { ReviewDetail } from "@pages/reviews/review-detail"
import { ReviewList } from "@pages/reviews/review-list"
import { RoomManagement } from "@pages/room-management"
import { StripeCheckout } from "@pages/stripe/checkout"
import { StripeError } from "@pages/stripe/error"
import { StripeSuccess } from "@pages/stripe/success"
import { Terms } from "@pages/terms"
import { ErrorBoundary } from "@sentry/react"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { Routes, Route, useLocation, Navigate } from "react-router-dom"

if (Capacitor.isPluginAvailable("StatusBar")) {
    void StatusBar.setOverlaysWebView({ overlay: true })
}

function MotionPage(props: React.PropsWithChildren<object>) {
    return (
        <motion.div
            initial={{ opacity: 0, transform: "translateX(100%)" }}
            animate={{ opacity: 1, transform: "translateX(0%)" }}
            exit={{ opacity: 0, transform: "translateX(-100%)" }}
            className="absolute inset-0"
        >
            {props.children}
        </motion.div>
    )
}

export function App() {
    const isHydrated = useAppState((state) => state.isHydrated)
    const location = useLocation()
    const locationArr = location.pathname?.split("/") ?? []

    useDeepLinkRedirect()
    useIntro()

    if (!isHydrated) {
        return null
    }

    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <StyledToaster />

            <Routes>
                <Route
                    path="/*"
                    errorElement={<ErrorFallback />}
                    element={
                        <>
                            <AnimatePresence initial={false}>
                                <Routes location={location} key={locationArr[1]}>
                                    <Route
                                        path="/auth/*"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Routes location={location} key={locationArr[2]}>
                                                        <Route path="/intro" element={<Intro />} />
                                                        <Route path="/login-selection" element={<LoginSelection />} />
                                                        <Route path="/login" element={<Login />} />
                                                        <Route
                                                            path="/register-selection"
                                                            element={<RegisterSelection />}
                                                        />
                                                        <Route path="/register" element={<Register />} />
                                                        <Route
                                                            path="/register-additions"
                                                            element={<RegisterAdditions />}
                                                        />
                                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                                        <Route
                                                            path="/forgot-confirmation"
                                                            element={<ForgotConfirmation />}
                                                        />
                                                        <Route path="/reset-password" element={<ResetPassword />} />
                                                        <Route path="/confirmation" element={<Confirmation />} />
                                                        <Route path="/invalid-token" element={<InvalidToken />} />
                                                        <Route path="*" element={<Navigate to={"/login"} />} />
                                                    </Routes>
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/authenticated/*"
                                        errorElement={<ErrorFallback />}
                                        element={
                                            <MotionPage>
                                                <AuthenticatedLayout>
                                                    <Routes location={location} key={locationArr[2]}>
                                                        <Route path="/chat/:id" element={<Chat />} />
                                                        <Route path="/chat" element={<InitialProjectLoader />} />
                                                        <Route path="/account" element={<Account />} />
                                                        <Route
                                                            path="/project-overview/:id"
                                                            element={<ProjectOverview />}
                                                        />
                                                        <Route
                                                            path="/energy-certificates/:id"
                                                            element={<EnergyCertificates />}
                                                        />
                                                        <Route path="/building-pass/:id" element={<BuildingPass />} />
                                                        <Route
                                                            path="/project-settings/:id"
                                                            element={<ProjectSettings />}
                                                        />
                                                        <Route path="/data-overview/:id" element={<DataOverview />} />
                                                        <Route
                                                            path="/data-protection/:id"
                                                            element={<DataProtection />}
                                                        />
                                                        <Route path="/terms/:id" element={<Terms />} />
                                                        <Route
                                                            path="/room-management/:id"
                                                            element={<RoomManagement />}
                                                        />
                                                        <Route path="/reviews" element={<ReviewList />} />
                                                        <Route path="/review/:id" element={<ReviewDetail />} />
                                                        <Route
                                                            path="/review-confirmation/:id"
                                                            element={<ReviewConfirmation />}
                                                        />
                                                        <Route
                                                            path="*"
                                                            element={<Navigate to={"/authenticated/chat"} />}
                                                        />
                                                    </Routes>
                                                </AuthenticatedLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/payment/*"
                                        element={
                                            <MotionPage>
                                                <Routes location={location} key={locationArr[2]}>
                                                    <Route path="/checkout" element={<StripeCheckout />} />
                                                    <Route path="/error" element={<StripeError />} />
                                                    <Route path="/success" element={<StripeSuccess />} />
                                                </Routes>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/connect/*"
                                        element={
                                            <MotionPage>
                                                <Routes location={location} key={locationArr[2]}>
                                                    <Route
                                                        path="/google/user-redirect"
                                                        element={<UserRedirect to="/connect/google/redirect" />}
                                                    />
                                                    <Route path="/google/redirect" element={<GoogleRedirect />} />

                                                    <Route
                                                        path="/facebook/user-redirect"
                                                        element={<UserRedirect to="/connect/facebook/redirect" />}
                                                    />
                                                    <Route path="/facebook/redirect" element={<FacebookRedirect />} />

                                                    <Route
                                                        path="/apple/user-redirect"
                                                        element={<UserRedirect to="/connect/apple/redirect" />}
                                                    />
                                                    <Route path="/apple/redirect" element={<AppleRedirect />} />
                                                </Routes>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/data-protection"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <DataProtection />
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route
                                        path="/terms"
                                        element={
                                            <MotionPage>
                                                <AuthLayout>
                                                    <Terms />
                                                </AuthLayout>
                                            </MotionPage>
                                        }
                                    />

                                    <Route path="*" element={<Navigate to={"/authenticated/chat"} />} />
                                </Routes>
                            </AnimatePresence>
                        </>
                    }
                ></Route>
            </Routes>
        </ErrorBoundary>
    )
}
