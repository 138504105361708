import { cls } from "@utils"
import { IGradientBorder, Variant } from "."

export function GradientBorder(props: IGradientBorder) {
    const { variant, radius, children, className, ...divProps } = props
    const variants: Record<Variant, string> = {
        dark: "from-primaryAccent2/20 to-primaryAccent2/10",
        light: "from-primaryAccent3/30 to-primaryAccent2/30",
    }
    return (
        <div
            {...divProps}
            className={cls(
                "relative grid bg-gradient-to-b p-[1px]",
                variants[variant],
                {
                    "rounded-lg": radius === "small",
                    "rounded-3xl": radius === "big",
                    "rounded-full": radius === "full",
                },
                className
            )}
        >
            {children}
        </div>
    )
}
