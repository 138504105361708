export * from "./heading"
export * from "./text"

export type TextVariant = "body1" | "body2" | "strong1" | "strong2" | "label" | "small" | "tiny"

export const variants: Record<TextVariant, string> = {
    body1: "font-text text-body1",
    body2: "font-text text-body2",
    strong1: "font-text text-strong1",
    strong2: "font-text text-strong2",
    label: "font-heading text-label",
    small: "font-text text-small",
    tiny: "font-text text-tiny",
}
