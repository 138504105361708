import { useAppState } from "@hooks/useState"
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query"
import { toast } from "sonner"

export const baseUrl = import.meta.env.VITE_BACKEND_URL
export const apiUrl = `${baseUrl}/api`

const handleError = (error: Error) => {
    if (error instanceof UnauthorizedError) {
        useAppState.getState().clearAuth()
    }
    console.error(error)
    toast.error(error.message ?? "Unbekannter Fehler")
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
        },
    },
    queryCache: new QueryCache({
        onError: handleError,
    }),
    mutationCache: new MutationCache({
        onError: handleError,
    }),
})

export class ParseError extends Error {}
export class BackendError extends Error {}
export class UnauthorizedError extends Error {}

export async function handleFetch(fetchCall: () => Promise<Response | null>) {
    try {
        const response = await fetchCall()
        if (!response) {
            return null
        }

        if (!response.ok) {
            if (response.status === 401) {
                throw new UnauthorizedError("Anmeldung abgelaufen")
            }

            const errorResult = await response.json()
            throw new BackendError(errorResult.error.message)
        }

        return await response.json()
    } catch (error) {
        if (error instanceof UnauthorizedError) {
            throw error
        } else if (error instanceof BackendError) {
            throw error
        } else {
            console.error("Error Message: ", error)
            throw new Error("Anfrage fehlgeschlagen")
        }
    }
}

export function handleParse<T>(func: () => T) {
    try {
        return func()
    } catch (error) {
        console.error(error)
        throw new ParseError("Lesen von Serverdaten fehlgeschlagen")
    }
}
