import { queryClient } from "@api/apiConfig"
import { useCertificate } from "@api/certificate/useCertificate"
import { useLastSentChatId } from "@api/chat/useLastSentChatId"
import { useOrders } from "@api/order/useOrders"
import { Preferences } from "@capacitor/preferences"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { Currency, EnergyCertificateType, EnergyCertificateVariant, IOrder, ProductId } from "@energuide/shared"
import { useStripe } from "@libs/stripe-lib"
import { useCallback, useEffect, useMemo, useState } from "react"
import { CertificateCreatePreview } from "./parts/createPreview"
import { CertificateBoxDownload } from "./parts/download"
import { CertificateBoxDownloadPreview } from "./parts/downloadPreview"
import { CertificateBoxProcessing } from "./parts/processing"
import { CertificateBoxPurchase } from "./parts/purchase"
import { ProgressBar } from "@components/atoms/progress"

enum DemandCertificateState {
    CREATE_PREVIEW,
    DOWNLOAD_PREVIEW,
    PURCHASE,
    PROCESSING,
    DOWNLOAD,
}

interface Props {
    title: string
    price: Currency
    progress: {
        value: number
        max: number
    }
    projectId: number
    type: EnergyCertificateType
    productId: ProductId
}

enum PreviewValues {
    YES = "yes",
    NO = "no",
}

export function CertificateGenerationBox(args: Props) {
    const { price, progress, projectId, type, productId, title } = args

    const [demandCertificateState, setDemandCertificateState] = useState<DemandCertificateState>(
        DemandCertificateState.CREATE_PREVIEW
    )
    const [isCreating, setIsCreating] = useState(false)

    const [downloadedPreview, setDownloadedPreview] = useState(false)
    useEffect(() => {
        void hasViewedPreview().then((v) => setDownloadedPreview(v))
    }, [])

    async function hasViewedPreview(): Promise<boolean> {
        const downloadedPreview = await Preferences.get({ key: `has-${productId}-downloaded` })
        return downloadedPreview.value === PreviewValues.YES
    }
    async function setViewedPreview(v: PreviewValues): Promise<void> {
        await Preferences.set({ key: `has-${productId}-downloaded`, value: v })
    }

    const lastChatId = useLastSentChatId(projectId)
    const certificate = useCertificate(projectId, type)
    const isPreviewUpToDate = useMemo<boolean>(
        () => certificate.data?.lastSentChatId === lastChatId.data?.last_sent_message_chat_id,
        [certificate, lastChatId]
    )
    const orders = useOrders({ projectId })
    const order = useMemo<IOrder | undefined>(
        () => orders.data?.find((order) => order.product.id === productId),
        [orders, productId]
    )

    useEffect(() => {
        if (certificate.data) {
            if (certificate.data.final?.src) {
                setDemandCertificateState(DemandCertificateState.DOWNLOAD)
            } else if (order) {
                if (order.is_cancelled) {
                    setDemandCertificateState(DemandCertificateState.PROCESSING)
                } else if (order.is_captured || order.is_paid) {
                    setDemandCertificateState(DemandCertificateState.PROCESSING)
                } else {
                    setDemandCertificateState(DemandCertificateState.PURCHASE)
                }
            } else if (certificate.data.draft?.src && isPreviewUpToDate) {
                if (downloadedPreview) {
                    setDemandCertificateState(DemandCertificateState.PURCHASE)
                } else {
                    setDemandCertificateState(DemandCertificateState.DOWNLOAD_PREVIEW)
                }
            } else {
                if (certificate.data.druckappInput && isPreviewUpToDate) {
                    setIsCreating(true)
                }
                setDemandCertificateState(DemandCertificateState.CREATE_PREVIEW)
            }
        }
    }, [certificate, order, downloadedPreview, isPreviewUpToDate])

    function onNextState() {
        switch (demandCertificateState) {
            case DemandCertificateState.CREATE_PREVIEW:
                void certificate.refetch()
                break
            case DemandCertificateState.DOWNLOAD_PREVIEW:
                setDownloadedPreview(true)
                void setViewedPreview(PreviewValues.YES)
                break
            case DemandCertificateState.PURCHASE:
                setDemandCertificateState(DemandCertificateState.PROCESSING)
                void orders.refetch()
                break
            case DemandCertificateState.PROCESSING:
                void certificate.refetch()
                break
            case DemandCertificateState.DOWNLOAD:
                break
            default:
                break
        }
    }

    const stripeUtils = useStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
    const refreshOrderStatus = useCallback(async () => {
        void stripeUtils.refreshOrderStatus(projectId, productId).then((order) => {
            if (order?.is_cancelled) {
                void queryClient.invalidateQueries({ queryKey: ["order", projectId] })
            }
        })
    }, [stripeUtils, productId, projectId])
    useEffect(() => {
        if (order) {
            void refreshOrderStatus()
        }
    }, [order, refreshOrderStatus])

    return (
        <>
            <div className="relative grid gap-1">
                <Heading level="h4" className="text-text">
                    {title}
                </Heading>
                <Text variant="body2" className=" text-textVeryLight">
                    {`${progress.value}/${progress.max} Fragen beantwortet`}
                </Text>
            </div>
            <ProgressBar value={progress.value} max={progress.max} />

            {demandCertificateState === DemandCertificateState.CREATE_PREVIEW &&
                progress.max > 0 && 
                progress.value === progress.max &&
                lastChatId.data?.last_sent_message_chat_id && (
                    <CertificateCreatePreview
                        isCreating={isCreating}
                        projectId={projectId}
                        onNext={onNextState}
                        type={type}
                        lastSentChatId={lastChatId.data.last_sent_message_chat_id}
                    />
                )}
            {demandCertificateState === DemandCertificateState.DOWNLOAD_PREVIEW && certificate.data?.draft && (
                <CertificateBoxDownloadPreview pdfSrc={certificate?.data?.draft?.src ?? ""} onNext={onNextState} />
            )}
            {demandCertificateState === DemandCertificateState.PURCHASE &&
                certificate.data &&
                certificate.data.draft && (
                    <CertificateBoxPurchase
                        price={price}
                        projectId={projectId}
                        certificateId={certificate.data.id}
                        onNext={onNextState}
                        previewSrc={certificate?.data?.draft?.src ?? ""}
                        productId={productId}
                    />
                )}
            {demandCertificateState === DemandCertificateState.PROCESSING &&
                lastChatId.data?.last_sent_message_chat_id && (
                    <CertificateBoxProcessing
                        projectId={projectId}
                        variant={EnergyCertificateVariant.Final}
                        onNext={onNextState}
                        lastSentChatId={lastChatId.data?.last_sent_message_chat_id}
                        type={type}
                        productId={productId}
                    />
                )}
            {demandCertificateState === DemandCertificateState.DOWNLOAD && certificate.data?.final?.src && (
                <CertificateBoxDownload certificateUrl={certificate.data.final.src} />
            )}
        </>
    )
}
