import * as Progress from "@radix-ui/react-progress"
import { cls } from "@utils"
import { useMemo } from "react"

type ICorner = {
    className?: string
}

function Corner(props: ICorner) {
    const { className } = props
    return (
        <div className={cls("h-[6px] w-[6px] border-l-[1px] border-t-[1px] border-primaryAccent3/50", className)}></div>
    )
}

export type IProgressBar = {
    value: number
    max: number
}

export function ProgressBar(props: IProgressBar) {
    const { value, max } = props
    const ratio = useMemo(() => (max === 0 ? 0 : value / max), [value, max])

    return (
        <div className="relative p-2">
            <Corner className="absolute left-0 top-0" />
            <Corner className="absolute right-0 top-0 scale-x-[-1]" />
            <Corner className="absolute bottom-0 right-0 scale-[-1]" />
            <Corner className="absolute bottom-0 left-0 scale-y-[-1]" />

            <Progress.Root
                className="relative h-2 overflow-clip rounded-full bg-primaryAccent3/30"
                value={value}
                max={max || 1} // 0 is not a valid prop value
            >
                <Progress.Indicator
                    className="h-2 rounded-full bg-primary"
                    style={{ transform: `translateX(-${(1 - ratio) * 100}%)` }}
                />
            </Progress.Root>
        </div>
    )
}
