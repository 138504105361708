import { IChat } from "@api/chat/schema"
import { useCreateChat } from "@api/chat/useCreateChat"
import React from "react"

export function useInitChat(chats: IChat[] | undefined, mutation: ReturnType<typeof useCreateChat>) {
    const initialized = React.useRef<boolean>(false)

    React.useEffect(() => {
        if (initialized.current || !chats || chats.length > 0) {
            return
        }

        initialized.current = true

        mutation.mutate({
            intent_type: "init_data_collection",
            message: "",
            photos: [],
            files: [],
        })
    }, [chats, mutation])
}
