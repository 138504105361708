import { useClearChat } from "@api/chat/useClearChat"
import { Button } from "@components/atoms/buttons"
import { MoreIcon } from "@components/atoms/icons"
import { Text } from "@components/atoms/typography"
import { useAppState } from "@hooks/useState"
import { useLidarPlugin } from "@libs/lidar-lib"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"

export type IContextMenu = {
    showBuildingPass?: boolean
    showEnergyCertificates?: boolean
    showProjectSettings?: boolean
    showDataExport?: boolean
    showRoomManagement?: boolean
}

export function ContextMenu(props: IContextMenu) {
    const { showBuildingPass, showEnergyCertificates, showDataExport, showProjectSettings, showRoomManagement } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const location = useLocation()
    const projectId = useAppState((state) => state.activeProjectId)
    const clearMutation = useClearChat(projectId)
    const { isSupported } = useLidarPlugin()

    React.useEffect(() => {
        setOpen(false)
    }, [location.pathname])
    const [isRoomManagementSupported, setIsRoomManagementSupported] = useState(false)

    React.useEffect(() => {
        void isSupported().then((isSupported) => {
            setIsRoomManagementSupported(isSupported)
        })
    }, [isSupported])

    return (
        <DropdownMenu.Root open={open} onOpenChange={setOpen}>
            <DropdownMenu.Trigger asChild>
                <Button variant="tertiary" className="text-strong2 text-text" data-testid="context-menu-button">
                    <MoreIcon />
                </Button>
            </DropdownMenu.Trigger>

            <AnimatePresence>
                {open && (
                    <DropdownMenu.Portal forceMount>
                        <DropdownMenu.Content sideOffset={16} forceMount asChild>
                            <motion.div
                                className="z-20 grid gap-2 bg-backgroundStrong p-4 text-primaryAccent2"
                                initial={{ y: "-10%", opacity: 0 }}
                                animate={{ y: "0%", opacity: 1 }}
                                transition={{
                                    ease: "circInOut",
                                    duration: 0.1,
                                }}
                                data-testid="context-menu-container"
                            >
                                {showBuildingPass && (
                                    <DropdownMenu.Item className="flex items-center gap-4">
                                        <i className="ri-file-list-2-line text-[1.5rem] text-text"></i>
                                        <Link to={`/authenticated/building-pass/${projectId}`}>
                                            <Text variant="body2" className="text-textLight">
                                                Gebäudepass
                                            </Text>
                                        </Link>
                                    </DropdownMenu.Item>
                                )}
                                {showEnergyCertificates && (
                                    <DropdownMenu.Item className="flex items-center gap-4">
                                        <i className="ri-contract-line text-[1.5rem] text-text"></i>
                                        <Link to={`/authenticated/energy-certificates/${projectId}`}>
                                            <Text variant="body2" className="text-textLight">
                                                Energieausweise
                                            </Text>
                                        </Link>
                                    </DropdownMenu.Item>
                                )}
                                {showProjectSettings && (
                                    <DropdownMenu.Item className="flex items-center gap-4">
                                        <i className="ri-tools-fill text-[1.5rem] text-text"></i>
                                        <Link to={`/authenticated/project-settings/${projectId}`}>
                                            <Text variant="body2" className="text-textLight">
                                                Projekteinstellungen
                                            </Text>
                                        </Link>
                                    </DropdownMenu.Item>
                                )}
                                {showDataExport && (
                                    <DropdownMenu.Item className="flex items-center gap-4">
                                        <i className="ri-upload-line text-[1.5rem] text-text"></i>
                                        <Link to="">
                                            <Text variant="body2" className="text-textLight">
                                                Datenexport
                                            </Text>
                                        </Link>
                                    </DropdownMenu.Item>
                                )}
                                {showRoomManagement && isRoomManagementSupported && (
                                    <DropdownMenu.Item className="flex items-center gap-4">
                                        <i className="ri-home-4-line text-[1.5rem] text-text"></i>
                                        <Link to={`/authenticated/room-management/${projectId}`}>
                                            <Text variant="body2" className="text-textLight">
                                                Room Management
                                            </Text>
                                        </Link>
                                    </DropdownMenu.Item>
                                )}
                                <DropdownMenu.Item className="flex items-center gap-4">
                                    <i className="ri-delete-bin-line text-[1.5rem] text-text"></i>
                                    <Button
                                        type="button"
                                        variant="freeform"
                                        onClick={() => clearMutation.mutate()}
                                        data-testid="chat-clear-button"
                                    >
                                        <Text variant="body2" className="text-textLight">
                                            Chat löschen
                                        </Text>
                                    </Button>
                                </DropdownMenu.Item>
                            </motion.div>
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                )}
            </AnimatePresence>
        </DropdownMenu.Root>
    )
}
