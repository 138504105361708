import { Button } from "@components/atoms/buttons"
import { Heading, Text } from "@components/atoms/typography"
import { useMobile } from "@hooks/useMobile"
import { useSafeArea } from "@hooks/useSafeArea"
import * as Dialog from "@radix-ui/react-dialog"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

export type IDataModal = React.PropsWithChildren<{
    iconClass: string
    title?: string
    description?: string
    open: boolean
    onOpenChange: (open: boolean) => void
    trigger: React.ReactNode
}>

export function DataModal(props: IDataModal) {
    const { iconClass, title, description, children, open, onOpenChange, trigger } = props
    const inset = useSafeArea()
    const isMobile = useMobile()

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
            <AnimatePresence>
                {open && (
                    <Dialog.Portal forceMount>
                        <Dialog.DialogOverlay className="fixed bottom-0 left-0 right-0 top-0" asChild>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    ease: "circInOut",
                                }}
                                className="h-full w-full bg-background/50 backdrop-blur-sm"
                            ></motion.div>
                        </Dialog.DialogOverlay>
                        <Dialog.Content
                            className={cls("grid grid-cols-1 justify-items-center", {
                                "fixed bottom-0 left-0 z-30 w-full": isMobile,
                                "fixed left-[50%] top-[50%] z-30 w-full max-w-lg translate-x-[-50%] translate-y-[-50%]":
                                    !isMobile,
                            })}
                        >
                            <motion.div
                                initial={{
                                    y: isMobile ? "100%" : "0%",
                                    opacity: isMobile ? 1 : 0,
                                }}
                                animate={{
                                    y: "0%",
                                    opacity: 1,
                                }}
                                exit={{
                                    y: isMobile ? "100%" : "0%",
                                    opacity: isMobile ? 1 : 0,
                                }}
                                transition={{
                                    ease: "circInOut",
                                }}
                                className={cls(
                                    "border-1 grid w-full gap-3 rounded-t-3xl border border-white/10 bg-backgroundStrong p-6 pt-3 md:max-w-xl",
                                    {
                                        "rounded-t-3xl border-b-0": isMobile,
                                        "rounded-3xl": !isMobile,
                                    }
                                )}
                                style={{ paddingBottom: `calc(${inset.bottom}px + 1.5rem)` }}
                            >
                                <div className="grid grid-cols-[auto,auto] items-center justify-between">
                                    <i
                                        className={cls(
                                            iconClass,
                                            "grid aspect-square h-9 w-9 place-items-center rounded-full bg-secondary text-[1.5rem] text-backgroundStrong"
                                        )}
                                    ></i>
                                    <Dialog.Close asChild>
                                        <Button
                                            variant="freeform"
                                            className="px-0"
                                            data-testid="data-modal-close-button"
                                        >
                                            <i className="ri-close-line text-[1.5rem] leading-none text-textVeryLight"></i>
                                        </Button>
                                    </Dialog.Close>
                                </div>

                                {title ? (
                                    <Dialog.Title asChild>
                                        <Heading level="h4" className="text-text">
                                            {title}
                                        </Heading>
                                    </Dialog.Title>
                                ) : null}

                                {description ? (
                                    <Dialog.Description asChild>
                                        <Text variant="body2" className="text-textLight">
                                            {description}
                                        </Text>
                                    </Dialog.Description>
                                ) : null}

                                {children}
                            </motion.div>
                        </Dialog.Content>
                    </Dialog.Portal>
                )}
            </AnimatePresence>
        </Dialog.Root>
    )
}
