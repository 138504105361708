import { Photo } from "@capacitor/camera"
import { PickedFile } from "@capawesome/capacitor-file-picker"
import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import { PreviewImage } from "@components/molecules/preview-image"
import { useHeight } from "@hooks/useHeight"
import { useMobile } from "@hooks/useMobile"
import * as Form from "@radix-ui/react-form"
import { pickedFileToUrl } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"

type IChatInput = {
    photos: Photo[]
    files: PickedFile[]
    onRemoveImage?: (id: string) => void
    onCaptureImage?: () => void
    onRemoveFile?: (file: PickedFile) => void
    onPickFile?: () => void
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
}

export const ChatInput = React.forwardRef<HTMLTextAreaElement, IChatInput>((props, ref) => {
    const { photos, onRemoveImage, onCaptureImage, files, onRemoveFile, onPickFile, onKeyDown } = props
    const { height } = useHeight(photos.length > 0 || files.length > 0)
    const isMobile = useMobile()

    return (
        <GradientBorder variant="light" radius="big">
            <Form.Field
                name="chat"
                className="grid grid-cols-auto-1fr-auto items-center rounded-3xl bg-backgroundStrong py-2"
            >
                <div className="col-span-3 flex gap-2 px-5">
                    <AnimatePresence initial={false}>
                        {photos.map((photo) => (
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height, opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ type: "tween" }}
                                className="overflow-hidden"
                                key={photo.webPath!}
                            >
                                <PreviewImage
                                    alt={"Ausgewähltes Bild für Upload"}
                                    path={photo.webPath!}
                                    onRemove={() => onRemoveImage?.(photo.webPath!)}
                                    className="pb-2 pr-3 pt-3"
                                />
                            </motion.div>
                        ))}
                        {files.map((file, idx) => {
                            return (
                                <motion.div
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height, opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ type: "tween" }}
                                    className="overflow-hidden"
                                    key={idx}
                                >
                                    <PreviewImage
                                        alt={"Ausgewählte Datei für Upload"}
                                        path={pickedFileToUrl(file)}
                                        onRemove={() => onRemoveFile?.(file)}
                                        className="pb-2 pr-3 pt-3"
                                        filename={file.name}
                                    />
                                </motion.div>
                            )
                        })}
                    </AnimatePresence>
                </div>

                <div className="flex gap-4 px-4">
                    <Button
                        variant="tertiary"
                        type="button"
                        onClick={onPickFile}
                        className="p-0"
                        data-testid="chat-attachment-button"
                    >
                        <i className="ri-attachment-line text-[1.5rem]"></i>
                    </Button>
                    {isMobile && (
                        <Button variant="tertiary" type="button" onClick={onCaptureImage} className="p-0">
                            <i className="ri-camera-3-line text-[1.5rem]"></i>
                        </Button>
                    )}
                </div>

                <Form.Control
                    asChild
                    className="h-28 resize-none overflow-hidden bg-transparent text-body2 text-primaryAccent2 focus-within:outline-none
                        focus-visible:outline-none"
                >
                    <TextareaAutosize
                        autoCapitalize="off"
                        autoCorrect="off"
                        maxRows={5}
                        onKeyDown={onKeyDown}
                        ref={ref}
                    />
                </Form.Control>

                <Button variant="tertiary" data-testid="chat-send-button">
                    <i className="ri-send-plane-2-fill text-[1.5rem]"></i>
                </Button>
            </Form.Field>
        </GradientBorder>
    )
})
