import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { EnergyCertificateResponse, transform } from "@api/certificate/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseDraft = {
    enabled?: boolean
}

export function useFinal(id: number, params?: IUseDraft) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["final", id],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/energy-certificate/final/${id}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const parsed = handleParse(() => EnergyCertificateResponse.parse(response.data))
            return transform(parsed)
        },
        enabled: !!enabled && !!authHeader,
        refetchInterval: (query) => {
            const { data } = query.state
            const isError = data?.final?.error
            const shouldRefetch = data && !data.final && !isError
            return shouldRefetch ? 5000 : false
        },
    })
}
