import { Heading } from "@components/atoms/typography"
import { ChangeableText } from "./changeable-field"
import { Reveal } from "./reveal"

type Entry = {
    label: string
    name: string
    value: string
}

type Section = {
    heading?: string
    entries: Entry[]
}

type ICollapsible = {
    title: string
    sections: Section[]
    onMutate?: (data: FormData) => Promise<void>
    defaultOpen?: boolean
}

export function DataBlock(props: ICollapsible) {
    const { title, sections, onMutate, defaultOpen } = props

    return (
        <Reveal title={title} defaultOpen={defaultOpen}>
            {sections.map(({ heading, entries }) => (
                <section className="grid gap-4 border-t-[1px] border-textDivider" key={heading}>
                    {heading ? <Heading level="h4">{heading}</Heading> : null}
                    {entries.map(({ label, name, value }) => (
                        <ChangeableText
                            changeableFieldProps={{ mode: "edit", label }}
                            inputProps={{ name, value }}
                            onMutate={onMutate}
                            key={name}
                        />
                    ))}
                </section>
            ))}
        </Reveal>
    )
}
