import { IChat, ISender } from "@api/chat/schema.ts"
import { LoadingDots } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { MarkdownInterpreter } from "@components/atoms/markdown-interpreter"
import { Text } from "@components/atoms/typography.tsx"
import { UserAvatar } from "@components/molecules/avatar.tsx"
import { ChoiceInput } from "@components/molecules/choice-input"
import { PreviewImage } from "@components/molecules/preview-image"
import { format } from "date-fns"
import { AnimatePresence, motion } from "framer-motion"

/**
 * Checks if all remaining messages by the assistant are actually answers to user questions.
 * Choices will stay active if all messages after the last choice are just answers to intermittend questions.
 * @param chats
 * @returns
 */
function isChoiceActive(chats: IChat[], choiceIdx: number) {
    // if current message is not a choice, the answer is not important
    const currentMessage = chats[choiceIdx]
    if (!currentMessage.choices) {
        return false
    }

    // if choice is the last message, it's always active
    if (choiceIdx === chats.length - 1) {
        return true
    }

    const remainingMessages = chats.slice(choiceIdx + 1)
    const remainingAssistantMessages = remainingMessages.filter((chat) => {
        return chat.role === "assistant"
    })

    const isActive = remainingAssistantMessages.every((chat) => {
        return chat.intent_type === "answer_user_question"
    })

    return isActive
}

type IChatMessages = {
    chats: IChat[]
    onChoiceSelected?: (message: string) => void
}

export function ChatMessages(props: IChatMessages) {
    const { chats, onChoiceSelected } = props

    return (
        <AnimatePresence initial={false}>
            {chats.map((chat, idx) => {
                const { id, sender, choices, intent_type } = chat

                if (intent_type === "init_data_collection") {
                    return null
                }

                return (
                    <motion.div
                        key={id}
                        id={`message-${id}`}
                        className="chat-message grid grid-cols-[auto_1fr] gap-4"
                        initial={{ opacity: 0, y: "50px" }}
                        animate={{ opacity: 1, y: "0%" }}
                    >
                        <ChatAvatar sender={sender} />
                        <ChatContent chat={chat} />

                        {choices ? (
                            <ChoiceInput
                                choices={choices}
                                onSelectionChanged={onChoiceSelected}
                                disabled={!isChoiceActive(chats, idx)}
                                className="col-start-2"
                            />
                        ) : null}
                    </motion.div>
                )
            })}
        </AnimatePresence>
    )
}

type IChatAvatar = {
    sender?: ISender | null
}

function ChatAvatar(props: IChatAvatar) {
    const { sender } = props

    if (!sender) {
        return <EnerGuide energuideClassName="h-12 w-12" />
    }

    return <UserAvatar src={sender.avatar?.url ?? ""} alt="" initials={"You"} variant="chat" />
}

type IChatContent = {
    chat: IChat
}

function ChatContent(props: IChatContent) {
    const { chat } = props
    const { sender, lastUpdate, content, files = [] } = chat
    const hasFiles = files.length > 0

    return (
        <div className="grid gap-1">
            <div className="grid grid-cols-[minmax(0,1fr)_auto] items-center justify-between gap-1 ">
                <Text variant="strong2" className="overflow-clip text-ellipsis text-text">
                    {sender ? sender.displayName : "EnerGuide"}
                </Text>
                <Text variant="tiny" className="text-textVeryLight">
                    {lastUpdate ? format(lastUpdate, "kk:mm") : null}
                </Text>
            </div>
            {!content && !hasFiles ? (
                <LoadingDots />
            ) : (
                <>
                    <div className="whitespace-pre-line" style={{ wordBreak: "break-word" }}>
                        <MarkdownInterpreter content={content ?? ""} />
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {files.map((file) => (
                            <PreviewImage
                                key={file.url}
                                path={file.url ?? ""}
                                alt="Hochgeladenes Bild"
                                allowPreview
                                className="justify-self-start"
                                filename={file.name ?? ""}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
