import { ProductId } from "../../product"
import { EnergyCertificateVariant } from "../schema"
import { CertificateSse } from "./base"
import { CertificateMessage } from "./model"

class CertificateSseReceiver<T> extends CertificateSse {
    _eventSource: EventSource
    constructor(projectId: string, productId: ProductId, variant: EnergyCertificateVariant, apiUrl: string) {
        super(projectId, productId, variant)
        this._eventSource = new EventSource(
            `${apiUrl}/order/sse?projectId=${projectId}&productId=${productId}&variant=${variant}`
        )
    }
    listen(callback: (msg: CertificateMessage<T>) => void) {
        this._eventSource.onmessage = (event: MessageEvent<string | CertificateMessage<T>>) => {
            let data: CertificateMessage<T>
            if (typeof event.data === "string") {
                try {
                    data = JSON.parse(event.data)
                    callback(data)
                } catch (e) {
                    console.error("Failed to parse or validate the message:", e)
                    return
                }
            } else {
                try {
                    data = event.data
                } catch (e) {
                    console.error("Failed to validate the message:", e)
                    return
                }
            }
            callback(data)
        }
    }
    close() {
        this._eventSource.close()
    }
}
export { CertificateSseReceiver }
