import { ErrorFallback } from "@components/atoms/error-fallback"
import { Appbar } from "@components/organisms/app-bar"
import { useAppState } from "@hooks/useState"
import { ErrorBoundary } from "@sentry/react"
import { cls } from "@utils"
import React, { PropsWithChildren } from "react"

type IAuthLayout = PropsWithChildren<{
    className?: string
}>

export function AuthLayout(props: React.PropsWithChildren<IAuthLayout>) {
    const { children, className } = props
    const backTo = useAppState((state) => state.backTo)
    const mode = useAppState((state) => state.mode)

    return (
        <div data-name="auth-content" className={cls("relative grid min-h-dvh grid-rows-[1fr]", className)}>
            <Appbar mode={mode} backTo={backTo} showContextMenu={false} title="" />
            <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>
        </div>
    )
}

export function Footer(props: React.PropsWithChildren<object>) {
    return (
        <div
            className="gradient-border-dark mx-auto grid w-full max-w-screen-md justify-items-center border-t bg-transparent pb-8 pl-8 pr-8
                pt-4"
        >
            {props.children}
        </div>
    )
}
