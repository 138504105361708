import React from "react"
import { useFilePicker } from "./useFilePicker"
import { useImagePicker } from "./useImagePicker"

type IUseFileSelection = {
    maxFiles: number
}

export function useFileSelection(params: IUseFileSelection) {
    const { maxFiles } = params

    const [numSelected, setNumSelected] = React.useState(0)

    const {
        photos,
        capture,
        reset: resetImages,
        remove: removeImage,
    } = useImagePicker({ multiple: true, maxFiles, totalFilesSelected: numSelected })

    const {
        files,
        pickFiles,
        reset: resetFiles,
        remove: removeFile,
    } = useFilePicker({ maxFiles, totalFilesSelected: numSelected })

    React.useEffect(() => {
        setNumSelected(files.length + photos.length)
    }, [files, photos])

    return {
        photos,
        capture,
        resetImages,
        removeImage,

        files,
        pickFiles,
        resetFiles,
        removeFile,
    }
}
