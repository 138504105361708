import { Button } from "@components/atoms/buttons"
import { useRef } from "react"

interface Props {
    onNext: () => void
    pdfSrc: string
}

export function CertificateBoxDownloadPreview(props: Props) {
    const { onNext, pdfSrc } = props
    const downloadLink = useRef<HTMLAnchorElement>(null)

    async function downloadPreview() {
        if (downloadLink.current) {
            downloadLink.current.click()
            onNext()
        }
    }

    return (
        <>
            <Button loading={false} disabled={false} variant="primary" onClick={downloadPreview}>
                Vorschau öffnen
                <i className="ri-file-download-fill text-[1.25rem]"></i>
            </Button>
            <a hidden href={pdfSrc} ref={downloadLink} target="_blank" />
        </>
    )
}
