import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ProgressResponse } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseProgress = {
    enabled?: boolean
}

export function useProgress(projectId: number, params?: IUseProgress) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["certificate", "progress", projectId],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/energy-certificate/progress?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const parsed = handleParse(() => ProgressResponse.parse(response))
            return parsed
        },
        enabled: !!enabled && !!authHeader,
    })
}
