import { z } from "zod"

//TODO: Bad design choice that we got ProductId and ProductIdentifier which are kinda the same but ProductIdentifier is more specific by including the project and variant -> refactor
enum ProductId {
    ConsumptionCertificate = 1, // Verbrauchsausweis
    DemandCertificate = 2, //Bedarfsausweis
}

const ProductIdSchema = z.nativeEnum(ProductId)

const ProductSchema = z.object({
    id: z.number(),
    name: z.string(),
    price: z.number().positive(),
})

type IProduct = z.infer<typeof ProductSchema>

// enum ProductType {
//     ConsumptionCertificate = "consumption",
//     DemandCertificate = "demand",
// }
// const ProductTypeSchema = z.nativeEnum(ProductType)
// function convertProductIdToType(productId: ProductId): ProductType {
//     switch (productId) {
//         case ProductId.ConsumptionCertificate:
//             return ProductType.ConsumptionCertificate
//         case ProductId.DemandCertificate:
//             return ProductType.DemandCertificate
//         default:
//             throw new Error("Invalid ProductId")
//     }
// }

// function convertProductTypeToId(productType: ProductType): ProductId {
//     switch (productType) {
//         case ProductType.ConsumptionCertificate:
//             return ProductId.ConsumptionCertificate
//         case ProductType.DemandCertificate:
//             return ProductId.DemandCertificate
//         default:
//             throw new Error("Invalid ProductType")
//     }
// }

export { ProductSchema, ProductId, ProductIdSchema }
export type { IProduct }
