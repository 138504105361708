import { useDeleteOrder } from "@api/order/deleteOrder"
import { useUser } from "@api/user/useUser"
import { Button, LinkButton } from "@components/atoms/buttons"
import { Currency, ProductId } from "@energuide/shared"
import { usePaymentChannel } from "@hooks/usePaymentChannel"
import { useScroll } from "@hooks/useScroll"
import { useStripe } from "@libs/stripe-lib"
import { useCallback, useEffect, useState } from "react"
import { toast } from "sonner"

interface Props {
    onNext: () => void
    price: Currency
    projectId: number
    certificateId: number
    previewSrc: string
    productId: ProductId
}

export function CertificateBoxPurchase(props: Props) {
    const { onNext, price, projectId, certificateId, previewSrc, productId } = props
    const [isCreating, setIsCreating] = useState(false)
    const stripeUtils = useStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
    const { data: user } = useUser()
    const { manualScroll } = useScroll("bottom", false, "smooth")
    const { listen, close } = usePaymentChannel()

    useEffect(() => {
        manualScroll("bottom")
    }, [manualScroll])

    const refreshOrderStatus = useCallback(async () => {
        const order = await stripeUtils.refreshOrderStatus(projectId, productId)
        if (order?.is_captured || order?.is_paid) {
            onNext()
        }
    }, [projectId, onNext, productId, stripeUtils])

    const success = useCallback(() => {
        toast.success("Zahlung erfolgreich!")
        close()
        void refreshOrderStatus().then(() => {
            onNext()
        })
    }, [refreshOrderStatus, close, onNext])

    const deleteOrder = useDeleteOrder()
    const error = useCallback(async () => {
        deleteOrder.mutate({ productId: productId, projectId: projectId })
        toast.error("Zahlung fehlgeschlagen!")
        setIsCreating(false)
        close()
    }, [close, deleteOrder, productId, projectId])

    const listenForPaymentSuccess = useCallback(
        (sessionId: string) => {
            listen((msg) => {
                if (msg.id === sessionId) {
                    if (msg.status === "success") {
                        success()
                    } else if (msg.status === "error") {
                        void error()
                    }
                } else {
                    console.warn("received msg from listen(msg) with different sessionId: ", msg)
                }
            })
        },
        [listen, error, success]
    )

    const processPayment = useCallback(async () => {
        if (!user?.email) {
            toast.error("Interner Fehler: Benutzer nicht gefunden")
            return
        }
        const { stripeSession } = await stripeUtils.order({
            email: user.email,
            productId: productId,
            projectId,
            certificateId,
        })

        const sessionId = stripeSession.id
        listenForPaymentSuccess(sessionId)

        window.open(`/payment/checkout?sessionId=${sessionId}`, "_blank")
        setIsCreating(true)
    }, [user, projectId, stripeUtils, listenForPaymentSuccess, productId, certificateId])

    useEffect(() => {
        void refreshOrderStatus()
        return () => {
            window.removeEventListener("focus", success)
            window.removeEventListener("focus", error)
        }
    }, [refreshOrderStatus, success, error])

    async function downloadPreview() {
        onNext()
    }

    return (
        <div>
            <p className="mb-4 mt-2 text-textLight">
                Dein vollwertiger Ausweis ohne Wasserzeichen steht bereit. Schließe die Bezahlung ab, um ihn sofort
                herunterzuladen.
            </p>
            <div>
                <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                        <span className="text-heading2">{price.formatted}</span>
                        <span className="text-small text-textLight">incl. MwSt.</span>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button
                            loading={isCreating}
                            disabled={isCreating}
                            variant="primary"
                            onClick={processPayment}
                            className="px-8"
                        >
                            Bezahlen
                        </Button>
                        <LinkButton
                            variant="secondary"
                            onClick={downloadPreview}
                            className="px-8"
                            href={previewSrc}
                            isExternal={true}
                        >
                            Vorschau
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
