import * as Dialog from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

type IPreviewImage = {
    path: string
    alt: string
    onRemove?: () => void
    allowPreview?: boolean
    className?: string
    filename?: string
}

export function PreviewImage(props: IPreviewImage) {
    const { allowPreview = false, alt, className, path, onRemove, filename } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [error, setError] = React.useState<boolean>(false)

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <div className={cls(className)}>
                <div className="relative">
                    <Dialog.Trigger asChild>
                        <button
                            type="button"
                            disabled={!allowPreview || error}
                            className="aspect-square h-24 overflow-hidden"
                        >
                            {!open && !error && (
                                <motion.img
                                    alt={alt}
                                    src={path}
                                    layoutId={path}
                                    className={cls("aspect-square h-full rounded-lg object-cover", {
                                        "opacity-0": open,
                                    })}
                                    onError={() => setError(true)}
                                />
                            )}
                            {error && (
                                <div className="grid h-full w-full items-end break-all rounded-lg bg-textVeryLight p-2 text-background">
                                    <i className="ri-file-line text-[1.8rem] text-background drop-shadow-lg"></i>
                                    <a href={path} className="grid" target="_blank">
                                        <span className="self-end text-[0.7rem] leading-3">{filename}</span>
                                    </a>
                                </div>
                            )}
                        </button>
                    </Dialog.Trigger>
                    <AnimatePresence>
                        {open && (
                            <Dialog.Portal forceMount>
                                <div className="fixed inset-0 grid grid-rows-[minmax(0,1fr)]">
                                    <Dialog.Overlay forceMount asChild>
                                        <motion.div
                                            className="h-full w-full bg-background/50"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            style={{ gridArea: "1/1" }}
                                        ></motion.div>
                                    </Dialog.Overlay>

                                    <Dialog.Content asChild>
                                        <motion.div
                                            layoutId={path}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className={cls("z-10 h-5/6 aspect-square max-w-full place-self-center")}
                                            style={{ gridArea: "1/1" }}
                                        >
                                            <img
                                                alt={alt}
                                                src={path}
                                                onClick={() => setOpen(false)}
                                                key="image"
                                                className="w-full h-full object-contain"
                                            />
                                            <VisuallyHidden.Root asChild>
                                                <Dialog.Title key="title">Vorschau</Dialog.Title>
                                            </VisuallyHidden.Root>
                                        </motion.div>
                                    </Dialog.Content>
                                </div>
                            </Dialog.Portal>
                        )}
                    </AnimatePresence>
                    {onRemove ? (
                        <button
                            type="button"
                            className="absolute right-0 top-0 grid h-10 w-10 translate-x-[50%] translate-y-[-50%] place-content-center"
                            onClick={onRemove}
                        >
                            <i className="ri-close-circle-fill text-[1.2rem] text-primaryAccent2 drop-shadow-lg"></i>
                        </button>
                    ) : null}
                </div>
            </div>
        </Dialog.Root>
    )
}
